export default {
  de: {
    phrases: {
      null: "",
      Yes: "Ja",
      No: "Nein",
      daily: "täglich",
      weekly: "wöchentlich",
      monthly: "monatlich",
      yearly: "jährlich",
      months: "Monate",
      Source: "Quelle",
      Copyrights: "Copyrights",
      Description: "Beschreibung",
      FileName: "Dateiname",
      NoMedia: "Es wurde noch keine Datei hinzugefügt",
      itemAddedMessage: "Datensatz wurde hinzugefügt",
      itemUpdatedMessage: "Datensatz wurde aktualisiert",
      Keywords: "Suchbegriffe",
      Search: "Suchen",
      NoFiles: "Es sind noch keine Dateien verfügbar.",
      NoCategories: "Es sind noch keine Kategorien verfügbar.",
    },
    controles: {
      Safe: "Speichern",
      Delete: "Löschen",
    },
    dialogs: {
      DeletePrompt: {
        title: "Bist du dir sicher?",
        text: "Möchtest du den Datensatz wirklich löschen?<br/>Das kann nicht rückgängig gemacht werden.",
      },
      Delete: "Löschen",
    },
    plugins: {
      mediapool: {
        title: "Medienpool",
        description: "Der Medienpool ist DIE zentrale Stelle für alle Medien.",
      },
    },
    messages: {
      SafeSuccess: {
        title: "Der Datensatz wurde gespeichert!",
        description: "",
      },
    },
    campaignChecklist: {
      title: "Titel wurde vergeben",
      budget: "Budget wurde vergeben",
      companies_id: "Firma wurde zugewiesen",
      dateStart: "Startdatum wurde vergeben",
      dateEndOrIsEndless: "Enddatum wurde gesetzt",
      dateEndInFuture: "Enddatum liegt in Zukunft",
      statusOpen: "Noch nicht freigegeben",
      companyFacebookDetailsExists: "Facebook Daten existieren",
      companyFacebookPageIdExists: "Facebook Page existiert",
      facebookPageAccessTokenLongExists: "Facebook AccessToken existiert",
      facebookCampaignId: "Facebook Kampagne existiert",
      facebookFormId: "Facebook Formular existiert",
      adSourceCustom: "Werbequelle wurde benannt",
      pagesId: "Landingpage wurde zugewiesen",
      facebookPageIdValidByFacebookApi:
        "Facebook-Page-ID erfolgreich überprüft",
      facebookCampaignIdValidByFacebookApi:
        "Facebook-Campaign-ID erfolgreich überprüft",
      facebookAdAccountIdExists:
        "Die Facebook-Account ID erfolgreich überprüft",
      statusChecked: "Die Qualität und Funktion wurde überprüft und abgenommen",
      statusAdProviderSetup:
        "Meta wurde vollständig eingerichtet und überprüft",
    },
  },

  en: {},
};
