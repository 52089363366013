<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row style="margin-bottom: 50px">
      <v-col cols="12">
        <DataTable
          @tableAction="emittedAction"
          v-bind:items="items"
          v-bind:headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
            copy: true,
          }"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="createNew"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Neuen Channel anlegen</span>
    </v-tooltip>

    <AreYouSureDialog
      title="Soll der Channel gelöscht werden?"
      text="Wenn du den Channel tatsächlich löschen möchtest, klicke auf 'Ja'. Ansonsten klicke auf 'Nein'."
      :dialog="dialog"
      @dialogEmit="dialogEmit"
    />

    <v-dialog
      v-model="detailDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Info-Mail-Channel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <ScoutingBonus :itemData="itemData" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
import ScoutingBonus from "@/components/tech/scouting_boni/ScoutingBonus.vue";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog.vue";
export default {
  name: "index",

  components: {
    PageHeader,
    DataTable,
    AreYouSureDialog,
    ScoutingBonus,
  },

  data() {
    return {
      pageTitle: "Info-Mail Channels",
      pageDescription: `<p>Hier werden die Info-Mail Channels angelegt und gepflegt.</p>`,
      title: "",
      preloader: false,
      items: [],
      headers: [],
      edit: true,
      trash: true,
      preview: true,
      copy: true,
      search: "",
      dialog: false,
      detailDialog: false,
      componentKey: 0,
      itemData: [],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async duplicateItem(id) {
      const userChoice = await this.$dialog.confirm({
        text: "Soll der Channel dupliziert werden? Der duplizierte Channel wird an das Ende der Liste angehängt.",
        title: "Bist du sicher?",
        waitForResult: true,
      });

      if (userChoice) {
        let response = await this.postRequest(
          "addons/infoMails/channels/" + id
        );
        if (response.status === 200) {
          // add to array at the end
          this.items.push(response.data.data);

          /* start snackbar */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Der Info-Mail Channel wurde erfolgreich dupliziert.";
          this.$root.snackbarGlobal.snackbarColor = "green";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },

    async deleteItem() {
      /* da ich die ID und den INDEX vorher den currentXX Vars zugewiesen habe, kann ich diese nun verwenden */
      let response = await this.deleteRequest(
        "addons/infoMails/channels/" + this.currentItemId
      );
      if (response.status === 200) {
        // reload entire page
        this.getData();
      }
    },

    dialogEmit(value) {
      if (value) {
        /* ANSWER: TRUE */

        this.deleteItem();
        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde erfolgreich gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else {
        /* ANSWER: FALSE */

        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde nicht gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async createNew() {
      /* create new entry */
      let response = await this.postRequest("addons/infoMails/channels"); // await data from mixin

      let newItem = response.data.data;
      this.items.splice(0, 0, newItem); // add to array at index 0

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Ein neuer Bonus wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */
        this.currentItemId = value.itemId;
        this.currentItemIndex = value.itemIndex;
        this.dialog = true; // starte den Dialog. Rückgabe erfolgt via emit nach dialogEmit()
      } else if (value.action === "editItem") {
        // redirect to tech.addons.info-mails-channels.:id
        this.$router.push({
          name: "tech.addons.info-mails-channels.:id",
          params: { id: value.item.id },
        });
      } else if (value.action === "copyItem") {
        this.currentItemId = value.itemId;
        this.duplicateItem(value.itemId);
      }
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest("addons/infoMails/channels");
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id", width: "80px" },
          { text: "Aktiv", value: "isActive", width: "80px" },
          { text: "Titel", value: "name" },
          {
            text: "",
            value: "actions",
            sortable: false,
            width: "120px",
          },
        ];

        this.items = response.data.data;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
