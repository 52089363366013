<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="12">
        <!-- router links -->
        <v-btn
          color="primary"
          class="ma-2"
          @click="$router.push('/tech/addons/shop/shops')"
        >
          <v-icon>mdi-cart</v-icon>
          <span>Kunden-Shops</span>
        </v-btn>

        <v-btn
          color="primary"
          :disabled="true"
          class="ma-2"
          @click="$router.push('/tech/addons/shop/products')"
        >
          <v-icon>mdi-tag-heart</v-icon>
          <span>Produkt-Templates</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <DataTable
          @tableAction="emittedAction"
          v-bind:items="items"
          v-bind:headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
          }"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="createNew"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Neuen Bonus anlegen</span>
    </v-tooltip>

    <AreYouSureDialog
      title="Soll das Produkt gelöscht werden?"
      text="Wenn du das Produkt tatsächlich löschen möchtest, klicke auf 'Ja'. Ansonsten klicke auf 'Nein'."
      :dialog="dialog"
      @dialogEmit="dialogEmit"
    />

    <v-dialog
      v-model="detailDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Produkt</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <Product :itemData="itemData" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
import Product from "@/views/tech/addons/shop/products/Product.vue";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog.vue";
export default {
  name: "ShopAddon-Products",

  components: {
    PageHeader,
    DataTable,
    AreYouSureDialog,
    Product,
  },

  data() {
    return {
      pageTitle: "Produkte",
      pageDescription: `<p>Hier werden die Produkte für die Kunden-Shops verwaltet. Alle Produkte werden zunächst hier angelegt und erst in einem weiteren Schritt in die Kunden-Shops übertragen.</p>`,
      title: "",
      preloader: false,
      items: [],
      headers: [],
      edit: true,
      trash: true,
      preview: true,
      copy: false,
      search: "",
      dialog: false,
      detailDialog: false,
      componentKey: 0,
      itemData: [],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async deleteItem() {
      /* da ich die ID und den INDEX vorher den currentXX Vars zugewiesen habe, kann ich diese nun verwenden */
      let response = await this.deleteRequest(
        "/addons/shop/productsTemplates/" + this.currentItemId
      );
      if (response.status === 200) {
        this.items.splice(this.currentItemIndex, 1); // remove from array
      }
    },

    dialogEmit(value) {
      if (value) {
        /* ANSWER: TRUE */

        this.deleteItem();
        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde erfolgreich gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else {
        /* ANSWER: FALSE */

        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde nicht gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async createNew() {
      /* create new entry */
      let response = await this.postRequest("/addons/shop/productsTemplates"); // await data from mixin

      let newItem = response.data.data;
      this.items.splice(0, 0, newItem); // add to array at index 0

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Ein neuer Bonus wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */
        this.currentItemId = value.itemId;
        this.currentItemIndex = value.itemIndex;
        this.dialog = true; // starte den Dialog. Rückgabe erfolgt via emit nach dialogEmit()
      } else if (value.action === "editItem") {
        this.componentKey += 1;
        this.itemData = value.item;
        this.itemIndex = value.index;
        this.detailDialog = true;
      }
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest("/addons/shop/productsTemplates");
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },
          { text: "Bild", value: "mediaUuid" },
          { text: "Titel", value: "title" },
          { text: "Kurz-Beschreibung", value: "descriptionShort" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
