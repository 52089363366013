<template>
  <v-card-text>
    <v-alert v-if="data.status === 'paused'" dense border="left" type="warning">
      Die Kampagne ist pausiert
    </v-alert>

    <v-row no-gutters>
      <v-col cols="12" class="d-flex pr-2">
        <CampaignStatus :data="data" />
      </v-col>
    </v-row>

    <CampaignLinks :data="data" />

    <template>
      <v-row
        no-gutters
        class="py-1 align-center"
        style="
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          margin-top: 30px !important;
        "
      >
        <v-col cols="3" class="font-weight-bold pa-2 black--text"> ID </v-col>
        <v-col cols="7" class="py-2 px-3">
          {{ data.id }}
        </v-col>

        <v-col cols="3" class="font-weight-bold pa-2 black--text"> UUID </v-col>
        <v-col cols="7" class="py-2 px-3">
          {{ data.uuid }}
        </v-col>

        <v-col cols="3" class="font-weight-bold pa-2 black--text">
          Firma
        </v-col>
        <v-col cols="7" class="py-2 px-3">
          <a :href="`/csm/company-manager/${data.companies_id}/overview`">
            {{ companyName }}
          </a>
        </v-col>

        <v-col cols="3" class="font-weight-bold pa-2 black--text">
          Quelle
        </v-col>
        <v-col cols="7" class="py-2 px-3">
          <v-chip v-if="data.planMonths_id" color="teal" class="white--text">
            JMP
          </v-chip>
          <v-chip v-else color="blue-grey" class="white--text">
            Direktkauf
          </v-chip>
        </v-col>

        <v-col cols="3" class="font-weight-bold pa-2 black--text">
          Facebook Werbekonto-ID
        </v-col>
        <v-col cols="7" class="py-2 px-3">
          <v-chip
            v-if="data.facebookAccountId"
            color="blue-grey"
            class="white--text"
          >
            {{ data.facebookAccountId }}
          </v-chip>
          <v-chip v-else color="blue-grey" class="white--text">
            Keine ID hinterlegt
          </v-chip>
        </v-col>
      </v-row>

      <Patcher
        :itemValue="data.title"
        itemName="title"
        itemLabel="Titel"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="text"
        :previewValue="data.title"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.title = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="data.name"
        itemName="name"
        itemLabel="Arbeitstitel"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="text"
        :previewValue="data.name"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.name = $event.value"
      ></Patcher>

      <h3 class="divider-hl primary darken-2 my-4">Landingpage</h3>

      <Patcher
        :itemValue="data.isPageless"
        itemName="isPageless"
        itemLabel="Keine Landingpage"
        :hideLabel="true"
        :route="patchRoute"
        inputElement="switch"
        inputType=""
        :disabled="data.status !== 'complete' ? false : true"
        :previewValue="data.isPageless"
        hint="Die Kampagne nutzt keine interne Landingpage"
        @emit="data.isPageless = $event.value"
      ></Patcher>

      <template v-if="!data.isPageless || data.isPageless === '0'">
        <h3 class="divider-hl primary darken-2 my-4">
          Zuweisung eines Landingpage-Templates
        </h3>
        <p>
          Hier kannst du der Kampagne ein Landingpage-Template zuweisen. Nach
          der Zuweisung wird eine Kopie des Templates angelegt, welches du an
          dieser Stelle indiviuell bearbeiten kannst.
        </p>
        <LinkLandingpageTemplate
          :pageId="data.pages_id"
          @pageLinked="getData()"
        />
      </template>

      <h3 class="divider-hl primary darken-2 my-4">Kategorisierungen</h3>

      <Patcher
        :itemValue="data.hasMitfitMember"
        itemName="hasMitfitMember"
        itemLabel="MEMBER-Kampagne"
        :hideLabel="true"
        :route="patchRoute"
        inputElement="switch"
        inputType=""
        :disabled="data.status !== 'complete' ? false : true"
        :previewValue="data.hasMitfitMember"
        hint="Die Kampagne ist eine mitfitMEMBER-Kampagne"
        @emit="data.hasMitfitMember = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="data.hasMitfitShop"
        itemName="hasMitfitShop"
        itemLabel="SHOP-Kampagne"
        :hideLabel="true"
        :route="patchRoute"
        inputElement="switch"
        inputType=""
        :disabled="data.status !== 'complete' ? false : true"
        :previewValue="data.hasMitfitShop"
        hint="Die Kampagne ist eine SHOP-Kampagne"
        @emit="data.hasMitfitShop = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="data.facebookLeadAds"
        itemName="facebookLeadAds"
        itemLabel="Facebook Lead-ADs Kampagne"
        :hideLabel="true"
        :route="patchRoute"
        inputElement="switch"
        inputType=""
        :disabled="data.status !== 'complete' ? false : true"
        :previewValue="data.facebookLeadAds"
        hint="Die Kampagne verwendet Facebook Lead-Ads"
        @emit="data.facebookLeadAds = $event.value"
      ></Patcher>

      <template v-if="data.facebookLeadAds === '1'">
        <h3 class="divider-hl primary darken-2 my-4">
          Facebook Lead-Ad Einstellungen
        </h3>
        <Patcher
          :itemValue="data.facebookCampaignId"
          itemName="facebookCampaignId"
          itemLabel="Facebook Kampagnen-ID"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="input"
          inputType="text"
          :disabled="data.status !== 'complete' ? false : true"
          :previewValue="data.facebookCampaignId"
          @emit="data.facebookCampaignId = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.facebookFormId"
          itemName="facebookFormId"
          itemLabel="Facebook Formular-ID"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="input"
          inputType="text"
          :previewValue="data.facebookFormId"
          :disabled="data.status !== 'complete' ? false : true"
          @emit="data.facebookFormId = $event.value"
        ></Patcher>
      </template>

      <h3 class="divider-hl primary darken-2 my-4">Laufzeit</h3>

      <Patcher
        :itemValue="data.dateStart"
        itemName="dateStart"
        itemLabel="Startdatum"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="datepicker"
        inputType=""
        :previewValue="formatDate(data.dateStart)"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.dateStart = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="data.isEndless"
        itemName="isEndless"
        itemLabel="Endlose Laufzeit"
        :hideLabel="true"
        :route="patchRoute"
        inputElement="switch"
        inputType=""
        :previewValue="data.isEndless"
        hint="Die Kampagne läuft endlos"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.isEndless = $event.value"
      ></Patcher>

      <template v-if="data.isEndless !== '1'">
        <Patcher
          :itemValue="data.dateEnd"
          itemName="dateEnd"
          itemLabel="Enddatum"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="datepicker"
          inputType=""
          :previewValue="formatDate(data.dateEnd)"
          :disabled="data.status !== 'complete' ? false : true"
          @emit="data.dateEnd = $event.value"
        ></Patcher>
      </template>

      <h3 class="my-4 divider-hl primary darken-2">Budget</h3>

      <Patcher
        :itemValue="data.budget"
        itemName="budget"
        itemLabel="Budget"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="number"
        :previewValue="data.budget"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.budget = $event.value"
      ></Patcher>

      <h3 class="my-4 divider-hl primary darken-2">Quellen</h3>

      <Patcher
        :itemValue="data.adSource"
        itemName="adSource"
        itemLabel="Werbequelle"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="select"
        inputType=""
        :previewValue="data.adSource"
        :selectValueKey="value"
        :selectTextKey="name"
        :selectOptions="adSources"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.adSource = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="data.adSourceCustom"
        itemName="adSourceCustom"
        itemLabel="Werbequelle Bezeichnung"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="text"
        :previewValue="data.adSourceCustom"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.adSourceCustom = $event.value"
      ></Patcher>

      <h3 class="my-4 divider-hl primary darken-2">Tracking</h3>

      <Patcher
        :itemValue="data.facebookPixelId"
        itemName="facebookPixelId"
        itemLabel="Facebook-Pixel ID"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="text"
        :previewValue="data.facebookPixelId"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.facebookPixelId = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="data.googleTagId"
        itemName="googleTagId"
        itemLabel="Google-Tag ID"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="text"
        :previewValue="data.googleTagId"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.googleTagId = $event.value"
      ></Patcher>

      <h3 class="divider-hl primary darken-2 my-4">
        Vielen Dank für die Teilnahme
      </h3>

      <p class="red--text font-weight-bold">
        Beachte: Die Texte gelten NICHT für mitfitMEMBER und mitfitSHOP
        Kampagnen.
      </p>

      <Patcher
        :previewValue="data.thanksEmailSubject"
        itemName="thanksEmailSubject"
        itemLabel="Betreff der E-Mail"
        :hideLabel="false"
        :itemValue="data.thanksEmailSubject"
        :route="patchRoute"
        inputElement="input"
        inputType="text"
        :selectOptions="null"
        selectValueKey=""
        selectTextKey=""
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.thanksEmailSubject = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="data.thanksEmail"
        itemName="thanksEmail"
        itemLabel="Inhalt der E-Mail"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="textarea-md"
        inputType="text"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.thanksEmail = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="data.thanksPage"
        itemName="thanksPage"
        itemLabel="Text auf der Seite"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="textarea-md"
        inputType="text"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.thanksPage = $event.value"
      ></Patcher>

      <v-sheet color="white" elevation="1" class="pa-4 text-caption caption">
        <strong>Platzhalter Bonus-Kalender:</strong><br />
        Bonus-Name: %bonuscalendar-item-title%<br />
        Zusätzlicher Text zum Bonus: %bonuscalendar-additional-text%
      </v-sheet>

      <h3 class="divider-hl primary darken-2 my-4">Weiterempfehlung</h3>

      <Patcher
        :itemValue="data.recommendationEnabled"
        itemName="recommendationEnabled"
        itemLabel="Weiterempfehlung aktiviert"
        :hideLabel="true"
        :route="patchRoute"
        inputElement="switch"
        inputType=""
        :previewValue="data.recommendationEnabled"
        hint="Dem Teilnehmer wird die Möglichkeit gegeben, die Kampagne weiterzuempfehlen"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.recommendationEnabled = $event.value"
      ></Patcher>

      <template v-if="data.recommendationEnabled === '1'">
        <Patcher
          :itemValue="data.recommendationText"
          itemName="recommendationText"
          itemLabel="Inhalt"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="textarea-md"
          inputType="text"
          :disabled="data.status !== 'complete' ? false : true"
          @emit="data.recommendationText = $event.value"
        ></Patcher>

        <Patcher
          :previewValue="data.customRecommendationUrl"
          itemName="customRecommendationUrl"
          itemLabel="Eigene URL, die empfohlen werden soll"
          :hideLabel="false"
          :itemValue="data.customRecommendationUrl"
          :route="patchRoute"
          inputElement="input"
          inputType="url"
          :selectOptions="null"
          selectValueKey=""
          selectTextKey=""
          :disabled="data.status !== 'complete' ? false : true"
          @emit="data.customRecommendationUrl = $event.value"
        ></Patcher>
      </template>

      <h3 class="divider-hl primary darken-2 my-4">Info-Mail Channels</h3>

      <p>
        Jeder Lead wird in die ausgewählten Info-Mail Channels eingetragen.
        <strong>ACHTUNG:</strong> Der Kunde benötigt das Addon
        <RouterLink
          :to="`/csm/company-manager/${data.companies_id}/addons`"
          target="_blank"
          >Info-Mails Channels</RouterLink
        >.
      </p>
      <Patcher
        v-if="!fetchingMailChannels"
        :itemValue="data.mailChannels_ids"
        itemName="mailChannels_ids"
        itemLabel="Info-Mail Channels"
        :route="patchRoute"
        inputElement="multiselect"
        inputType=""
        selectValueKey="id"
        selectTextKey="name"
        :selectOptions="allMailChannels"
        :previewValue="
          buildArrayWithNames(data.mailChannels_ids, 'mailChannels')
        "
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.mailChannels_ids = $event.value"
      ></Patcher>

      <h3 class="divider-hl primary darken-2 my-4">Externe APIs</h3>

      <p>Wähle die APIs aus, die für diese Kampagne verwendet werden sollen.</p>

      <template v-if="data.companies_id">
        <Patcher
          v-if="!fetchingExternalApis"
          :itemValue="data.externalApi_ids"
          itemName="externalApi_ids"
          itemLabel="Externe Anbieter"
          :route="patchRoute"
          inputElement="multiselect"
          inputType=""
          selectValueKey="providers_id"
          selectTextKey="name"
          :selectOptions="allExternalApis"
          :previewValue="
            buildArrayWithNames(data.externalApi_ids, 'externalApis')
          "
          :disabled="data.status !== 'complete' ? false : true"
          @emit="data.externalApi_ids = $event.value"
        ></Patcher>
      </template>
      <template v-else>
        <p>
          Bitte wähle zuerst eine Firma aus, um die externen APIs auswählen zu
          können.
        </p>
      </template>

      <h3 class="divider-hl primary darken-2 my-4">Events</h3>

      <p>Wähle das Event aus, das mit dieser Kampagne verknüpft werden soll.</p>

      <template v-if="data.companies_id">
        <Patcher
          v-if="!fetchingCompanyEvents"
          :itemValue="data.events_id"
          itemName="events_id"
          itemLabel="Event"
          :route="patchRoute"
          inputElement="select"
          inputType=""
          selectValueKey="id"
          selectTextKey="title"
          :selectOptions="allCompanyEvents"
          :previewValue="getItemName(data.events_id, 'company_events')"
          :disabled="data.status !== 'complete' ? false : true"
          @emit="data.events_id = $event.value"
        ></Patcher>
      </template>
      <template v-else>
        <p>
          Bitte wähle zuerst eine Firma aus, um ein Event auswählen zu können.
        </p>
      </template>

      <h3 class="divider-hl primary darken-2 my-4">Feedback-Request</h3>

      <Patcher
        :itemValue="data.feedbackEnabled"
        itemName="feedbackEnabled"
        itemLabel="Feedback nach X Tagen anfragen"
        :hideLabel="true"
        :route="patchRoute"
        inputElement="switch"
        inputType=""
        :previewValue="data.feedbackEnabled"
        hint="Dem Teilnehmer wird nach X Tagen die Möglichkeit gegeben, ein Feedback zu geben."
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.feedbackEnabled = $event.value"
      ></Patcher>

      <template v-if="data.feedbackEnabled === '1'">
        <!-- SELECT A FEEDBACK-SET -->
        <Patcher
          :itemValue="data.feedbackSets_id"
          itemName="feedbackSets_id"
          itemLabel="Feedback-Set"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="feedbackpicker"
          inputType=""
          previewValue=""
          selectOptions=""
          :disabled="data.status !== 'complete' ? false : true"
          @emit="data.feedbackSets_id = $event.value"
        ></Patcher>

        <Patcher
          :previewValue="data.feedbackRequestSubject"
          itemName="feedbackRequestSubject"
          itemLabel="Betreff der E-Mail"
          :hideLabel="false"
          :itemValue="data.feedbackRequestSubject"
          :route="patchRoute"
          inputElement="input"
          inputType="text"
          :selectOptions="null"
          selectValueKey=""
          selectTextKey=""
          :disabled="data.status !== 'complete' ? false : true"
          @emit="data.feedbackRequestSubject = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.feedbackRequestText"
          itemName="feedbackRequestText"
          itemLabel="Inhalt der E-Mail"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="textarea-md"
          inputType="text"
          hint="<strong>Platzhalter:</strong><br />
                    <strong>%firstName%</strong> - Vorname des Teilnehmers<br />
                    <strong>%feedbackUrl%</strong> - URL zur Feedback-Seite<br />
                    <strong>%studioName%</strong> - Name des Studios<br />
                    <strong>%recommendationUrl%</strong> - URL zur
                    Weiterempfehlung"
          :disabled="data.status !== 'complete' ? false : true"
          @emit="data.feedbackRequestText = $event.value"
        ></Patcher>

        <Patcher
          :previewValue="data.feedbackRequestAfterDays"
          itemName="feedbackRequestAfterDays"
          itemLabel="Nach wievielen Tagen soll das Feedback angefragt werden?"
          :hideLabel="false"
          :itemValue="data.feedbackRequestAfterDays"
          :route="patchRoute"
          inputElement="input"
          inputType="number"
          :selectOptions="null"
          selectValueKey=""
          selectTextKey=""
          :disabled="data.status !== 'complete' ? false : true"
          @emit="data.feedbackRequestAfterDays = $event.value"
        ></Patcher>
      </template>

      <h3 class="divider-hl primary darken-2 my-4">Scouting-Bonus</h3>
      <p>
        Gibt es einen Bonus für Leute, die die Kampagne weiterempfehlen? Dann
        kannst Du diesen hier auswählen.
      </p>
      <Patcher
        :itemValue="data.scoutingBonusId"
        itemName="scoutingBonusId"
        itemLabel="Scouting Bonus"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="bonuspicker"
        inputType=""
        previewValue=""
        selectOptions=""
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.scoutingBonusId = $event.value"
      ></Patcher>

      <template v-if="data.scoutingBonusId">
        <Patcher
          :itemValue="data.scoutingBonusStartDate"
          itemName="scoutingBonusStartDate"
          itemLabel="Startdatum"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="datepicker"
          inputType=""
          :disabled="data.status !== 'complete' ? false : true"
          :previewValue="formatDate(data.scoutingBonusStartDate)"
          @emit="data.scoutingBonusStartDate = $event.value"
        ></Patcher>
        <Patcher
          :itemValue="data.scoutingBonusEndDate"
          itemName="scoutingBonusEndDate"
          itemLabel="Enddatum"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="datepicker"
          inputType=""
          :disabled="data.status !== 'complete' ? false : true"
          :previewValue="formatDate(data.scoutingBonusEndDate)"
          @emit="data.scoutingBonusEndDate = $event.value"
        ></Patcher>

        <!-- DIRECT-BONUS -->

        <Patcher
          :itemValue="data.scoutingIsDirectBonus"
          itemName="scoutingIsDirectBonus"
          itemLabel="Direkt-Bonus"
          :hideLabel="true"
          :route="patchRoute"
          inputElement="switch"
          inputType=""
          :disabled="data.status !== 'complete' ? false : true"
          :previewValue="data.hasMitfitMember"
          hint="Der Bonus ist ein Direkt-Bonus, der bei Erfolg direkt ausgespielt wird."
          @emit="data.scoutingIsDirectBonus = $event.value"
        ></Patcher>

        <template v-if="data.scoutingIsDirectBonus">
          <Patcher
            :previewValue="data.scoutingDirectBonusEmailSubject"
            itemName="scoutingDirectBonusEmailSubject"
            itemLabel="Betreff der E-Mail"
            :hideLabel="false"
            :itemValue="data.scoutingDirectBonusEmailSubject"
            :route="patchRoute"
            inputElement="input"
            inputType="text"
            :selectOptions="null"
            selectValueKey=""
            selectTextKey=""
            :disabled="data.status !== 'complete' ? false : true"
            @emit="data.scoutingDirectBonusEmailSubject = $event.value"
          ></Patcher>

          <Patcher
            :itemValue="data.scoutingDirectBonusEmailBody"
            itemName="scoutingDirectBonusEmailBody"
            itemLabel="Inhalt der Bonus-E-Mail"
            :hideLabel="false"
            :route="patchRoute"
            inputElement="textarea-md"
            inputType="text"
            :disabled="data.status !== 'complete' ? false : true"
            @emit="data.scoutingDirectBonusEmailBody = $event.value"
          ></Patcher>
        </template>
      </template>

      <h3 class="divider-hl primary darken-2 my-4">Follow-Up-Kampagne</h3>
      <p>
        Hier kann die Kampagne ausgewählt werden, die nach Abschluss der
        Kampagne automatisch gestartet werden soll. Die Weiterleitung auf diese
        Kampagne ersetzt die Ausgabe der "Vielen Dank"-Seite.
      </p>

      <Patcher
        :itemValue="data.followUpCampaign_id"
        itemName="followUpCampaign_id"
        itemLabel="Follow-Up-Kampagne"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="campaignpicker"
        :disabled="data.status !== 'complete' ? false : true"
        @emit="data.followUpCampaign_id = $event.value"
      ></Patcher>

      <h3 class="divider-hl primary darken-2 my-4">Qualitäts-Checks</h3>
      <p>
        Bitte überprüfe die Kampagne, die dazugehörige Landingpage und die
        Einrichtung bei Facebook (Meta) auf Richtigkeit und fehlerfreie
        Ausführung.
      </p>

      <Patcher
        :itemValue="data.isChecked"
        itemName="isChecked"
        itemLabel="Kampagne wurde geprüft"
        :hideLabel="true"
        :route="
          data.isChecked === '0' ? patchRouteChecked : patchRouteUnsetChecked
        "
        inputElement="switch"
        inputType=""
        :previewValue="data.isChecked"
        :disabled="data.isApproved === '1' ? true : false"
        hint="Die Kampagne und Landingpage wurden auf Funktion und Darstellung geprüft und abgenommen."
        @emit="data.isChecked = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="data.adProviderSetup"
        itemName="adProviderSetup"
        itemLabel="Kampagne wurde auf Meta geprüft"
        :hideLabel="true"
        :route="
          data.adProviderSetup === '0'
            ? patchRoutePrioviderSetup
            : patchRouteUnsetPrioviderSetup
        "
        inputElement="switch"
        inputType=""
        :previewValue="data.adProviderSetup"
        :disabled="data.isApproved === '1' ? true : false"
        hint="Die Kampagne wurde bei Meta eingerichtet und auf Richtigkeit überprüft."
        @emit="data.adProviderSetup = $event.value"
      ></Patcher>
    </template>

    <Patcher
      :itemValue="data.isTest"
      itemName="isTest"
      itemLabel="Testkampagne"
      :hideLabel="true"
      :route="patchRoute"
      inputElement="switch"
      inputType=""
      :previewValue="data.isTest"
      :disabled="data.status !== 'complete' ? false : true"
      hint="Das ist eine Testkampagne"
      @emit="data.isTest = $event.value"
    ></Patcher>
  </v-card-text>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import Patcher from "@/components/ui/Patcher.vue";
import LinkLandingpageTemplate from "@/components/campaign_management/LinkLandingpageTemplate.vue";
import CampaignStatus from "@/components/campaign_management/CampaignStatus.vue";
import CampaignLinks from "@/components/campaign_management/CampaignLinks.vue";
export default {
  name: "CampaignSettings",

  props: ["data"],

  components: {
    Patcher,
    LinkLandingpageTemplate,
    CampaignStatus,
    CampaignLinks,
  },

  data() {
    return {
      companyName: "",
      pageDialog: false,
      notesKey: 0,
      statisticKey: 0,
      fetchingData: false,
      fetchingMailChannels: false,
      fetchingExternalApis: false,
      fetchingCompanyEvents: false,
      patchRoute: "campaigns/" + this.$route.params.id,
      patchRouteChecked: "campaigns/" + this.$route.params.id + "/setChecked",
      patchRouteUnsetChecked:
        "campaigns/" + this.$route.params.id + "/unsetChecked",
      patchRoutePrioviderSetup:
        "campaigns/" + this.$route.params.id + "/setAdProviderSetup",
      patchRouteUnsetPrioviderSetup:
        "campaigns/" + this.$route.params.id + "/unsetAdProviderSetup",

      pageTitle: "Kampagne: ",
      pageDescription: "",
      adSources: [
        {
          name: "Other",
          value: "Other",
        },
        {
          name: "Facebook Ads",
          value: "Facebook Ads",
        },
      ],
      recommendationTypes: [
        {
          name: "WhatsApp",
          value: "whatsapp",
        },
      ],
      value: null,
      name: null,
      StateKey: 0,
      campaignSetup: false,
      rerenderKey: 0,
      fb_account_id: null,
      allMailChannels: [],
      allExternalApis: [],
      allEvents: [],
      allCompanyEvents: [],
    };
  },

  created: function () {
    // check if this.data contains mailChannels_ids. if yes convert it to an array
    if (this.data.mailChannels_ids) {
      const carray = this.data.mailChannels_ids.split(",");
      this.data.mailChannels_ids = carray;
    }

    // check if this.data contains externalApi_ids. if yes convert it to an array
    if (this.data.externalApi_ids) {
      const carray = this.data.externalApi_ids.split(",");
      this.data.externalApi_ids = carray;
    }

    this.getAllMailChannels();
    if (this.data.companies_id) {
      this.getAllExternalApis();
      this.getAllCompanyEvents();
    }
  },

  mounted() {
    this.getCompanyName(this.data.companies_id).then((response) => {
      this.companyName = response;
    });
  },

  methods: {
    async fetchCompanyName(id) {
      return await this.getCompanyName(id);
    },

    async getCompanyName(id) {
      try {
        let response = await this.getRequest("companies/" + id);
        return response.data.data[0].name;
      } catch (error) {
        console.error("Fehler beim Abrufen des Firmennamens:", error);
        return null;
      }
    },

    async getAllMailChannels() {
      this.fetchingMailChannels = true;
      let response = await this.getRequest(`addons/infoMails/channels`);
      this.allMailChannels = response.data.data;
      this.fetchingMailChannels = false;
    },

    async getAllExternalApis() {
      // GET {{host}}/v1/companies/1/externalApis?filter[isCampaignApi]=1&filter[isValid]=1
      this.fetchingExternalApis = true;
      let response = await this.getRequest(
        `companies/${this.data.companies_id}/externalApis?filter[isCampaignApi]=1&filter[isValid]=1`
      );
      this.allExternalApis = response.data.data;
      this.fetchingExternalApis = false;
    },

    async getAllCompanyEvents() {
      // GET {{host}}/v1/companies/1/externalApis?filter[isCampaignApi]=1&filter[isValid]=1
      this.fetchingCompanyEvents = true;
      let response = await this.getRequest(
        `companies/${this.data.companies_id}/addons/eventcalendar/events`
      );
      this.allCompanyEvents = response.data.data;

      // add a blank event to the array
      this.allCompanyEvents.unshift({
        id: 0,
        title: "Kein Event",
      });
      this.fetchingCompanyEvents = false;
    },

    getItemName(id, type = "mailChannels") {
      // get the name of the item by id
      let name = "";

      if (!id || id === "0") {
        return "Kein Event";
      }

      if (type === "company_events") {
        this.allCompanyEvents.forEach((comp) => {
          if (comp.id === id) {
            name = comp.title;
          }
        });
      }
      return name;
    },

    buildArrayWithNames(items, type = "mailChannels") {
      if (!items) {
        return [];
      }

      // check if items is an array. if not convert it to an array
      if (!Array.isArray(items)) {
        items = items.split(",");
      }

      let nameArray = [];

      if (type === "mailChannels") {
        items.forEach((item) => {
          this.allMailChannels.forEach((comp) => {
            if (comp.id === item) {
              nameArray.push(comp.name);
            }
          });
        });
      } else if (type === "externalApis") {
        items.forEach((item) => {
          this.allExternalApis.forEach((comp) => {
            if (comp.providers_id === item) {
              nameArray.push(comp.name);
            }
          });
        });
      } else if (type === "company_events") {
        items.forEach((item) => {
          this.allCompanyEvents.forEach((comp) => {
            if (comp.id === item) {
              nameArray.push(comp.title);
            }
          });
        });
      }

      return nameArray;
    },
  },

  watch: {
    data: {
      handler() {
        this.StateKey += 1;
      },
      deep: true,
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
