<template>
  <v-card v-if="!fetchingData">
    <v-toolbar dark class="grey darken-3">
      <v-toolbar-title>Bestelltes Produkt</v-toolbar-title>
    </v-toolbar>

    <v-card-text v-if="data">
      <table class="default-table" style="width: auto">
        <tr>
          <td>ID</td>
          <td>{{ data.id }}</td>
        </tr>
        <tr>
          <td>UUID</td>
          <td>{{ data.uuid }}</td>
        </tr>
        <tr>
          <td>Name</td>
          <td>{{ data.name }}</td>
        </tr>
        <tr>
          <td>Einzelpreis</td>
          <td v-if="!data.planMonths_id">
            {{ data.price }} {{ data.currencyCode }}
          </td>
          <td v-else>
            <v-chip small color="teal" class="white--text"> JMP </v-chip>
          </td>
        </tr>
        <tr v-if="data.feePrice">
          <td>Zusatzgebühr</td>
          <td>{{ data.feePrice }} {{ data.currencyCode }}</td>
        </tr>
        <tr v-if="data.billingPeriod">
          <td>Abrechnungszeitraum</td>
          <td>{{ this.$t("phrases." + data.billingPeriod) }}</td>
        </tr>
        <tr v-if="data.runtimeCount">
          <td>Laufzeit</td>
          <td>
            {{ data.runtimeCount }}
            {{ this.$t("phrases." + data.runtimePeriod) }}
          </td>
        </tr>
        <tr v-if="data.renewalAutomatic">
          <td>Automatische Verlängerung</td>
          <td>{{ data.renewalAutomatic }}</td>
        </tr>
        <tr v-if="data.cancellationCount">
          <td>Kündigung</td>
          <td>
            {{ data.cancellationCount }}
            {{ this.$t("phrases." + data.runtimePeriod) }}
          </td>
        </tr>
        <tr>
          <td>Kaufdatum</td>
          <td>{{ formatDateTime(order.created) }}</td>
        </tr>
        <tr v-if="!data.planMonths_id">
          <td>Bezahlmethode</td>
          <td>{{ order.paymentMethodName }}</td>
        </tr>
        <tr v-if="!data.planMonths_id">
          <td>Währung</td>
          <td>{{ order.currencyCode }}</td>
        </tr>
        <tr v-if="!data.planMonths_id">
          <td>Gutscheincode</td>
          <td v-if="order.discountCode">
            {{ order.discountCode }} {{ data.discountCodeText }}
          </td>
          <td v-else>-</td>
        </tr>
        <tr v-if="!data.planMonths_id">
          <td>Notiz</td>
          <td>{{ order.note }}</td>
        </tr>
        <tr v-if="order && order.included && order.included.products[0]">
          <td>Vereinbarung</td>
          <td>{{ order.included.products[0].manualAgreement }}</td>
        </tr>
      </table>
      <BuyerCard :order="order" />
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import BuyerCard from "@/components/orders/BuyerCard.vue";
export default {
  name: "OrderSummary",

  components: {
    BuyerCard,
  },

  props: ["orderId", "ordersProducts_id"],

  data() {
    return {
      fetchingData: false,
      data: [],
      order: [],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.fetchingData = true;

      const response = await this.getRequest(
        `/orders/${this.orderId}/products/${this.ordersProducts_id}`
      );

      this.fetchingData = false;

      if (response.status === 200) {
        this.data = response.data.data;
      }

      this.fetchingData = true;
      const order = await this.getRequest(
        `/orders/${this.orderId}?include=products,buyer`
      );
      this.fetchingData = false;

      if (order.status === 200) {
        this.order = order.data.data;
      }
    },

    findProductTypeName(id) {
      let name = "";
      this.productTypes.forEach((item) => {
        if (id === item.id) {
          name = item.name;
        }
      });
      return name;
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
